import React, {useEffect, useState} from 'react';
import { h3ToGeo, geoToH3, edgeLength, kRing } from 'h3-js';
import logo from './logo.svg';
import './App.css';

function App(props) {
  
  const [theirLocation, setTheirLocation] = useState(null);
  const [theirHash, setTheirHash] = useState();
  const [loading, setIsLoading] = useState(true);
  const [nearby, setNearby] = useState([]);
  useEffect(() => {
    if (!theirLocation) {
      queryLocation();
    }
    getNearby();
  }, [theirLocation]);

  async function queryLocation() {
  if ("geolocation" in navigator) {
    console.log("Available");
    navigator.geolocation.getCurrentPosition(
      async function(position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      await setTheirLocation({"lat":position.coords.latitude, "lon": position.coords.longitude});
      setIsLoading(false);
      return getNearby()
      },
      function(error) {
        console.log("Error Code = "+ error.code + " - " + error.message);
        setTheirLocation({"lat":"-3.1883","lon":"55.9533"});
        setIsLoading(false)
        }
      );
  } else {
    console.log("Not Available");
  }
  }

  function getNearby() {
    console.log("being Called", theirLocation)
    if (theirLocation && !theirHash) {
      setTheirHash(geoToH3(theirLocation.lat, theirLocation.lon, 7));
      const radius = Math.floor(8 / (edgeLength(7, 'km') * 2));
      console.log(radius);
      setNearby(kRing(geoToH3(theirLocation.lat, theirLocation.lon, 7), radius));
      console.log(kRing(geoToH3(theirLocation.lat, theirLocation.lon, 7), radius))
      console.log(theirLocation);
    }
  }
  return (
    !loading &&
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Test search
        </p>
  <p>Your location is <code>{theirLocation.lat}, {theirLocation.lon}</code></p>
  {nearby.map((item, i)=> (
  <code key={item} >{item}({h3ToGeo(item)[0]}, {h3ToGeo(item)[1]})</code>
  ))}
  <pre>{theirHash}</pre>
      </header>
    </div>
  );
}

export default App;
